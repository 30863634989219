function readyHandler(fn: () => void) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

export default function mobileNav() {
  readyHandler(() => {
    const button = document.querySelector(".mobile-toggle");
    const header = document.querySelector("header");

    const handleClick = (evt: Event) => {
      evt.preventDefault();
      if (header) {
        header.classList.toggle("show-menu");
      }
    };

    if (button) {
      button.addEventListener("click", handleClick);
    }
  });
}
